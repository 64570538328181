import React from "react";
import LayoutHOC from "./LayoutHOC";

const Dashboard = () => {
  return <div className="p-8 ml-44">
  
  </div>;
};

export default LayoutHOC(Dashboard);
